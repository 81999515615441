export const cBlack = '#0c0013';
export const cWhite = '#f7edff';

export const cPurple1 = '#e7ceff';
export const cPurple2 = '#d6b4ff';
export const cPurple3 = '#ca9dff';
export const cPurple4 = '#bc83ff';
export const cPurple5 = '#ae6aff';
export const cPurple6 = '#a356ff';
export const cPurple7 = '#9339ff';
export const cPurple8 = '#6d17ce';
export const cPurple9 = '#4f0a9d';
export const cPurple10 = '#2a0357';
